import {building, copyright, defaultCoordinates, homeIcon} from "./map-data";
import leaflet from 'leaflet';

const map = leaflet.map("map", {
    attributionControl: true,
    zoomControl: false,
    scrollWheelZoom: false,
    keyboard: false
}).setView(defaultCoordinates, 17);

map.addLayer(copyright);
leaflet.marker(defaultCoordinates, {icon: homeIcon}).addTo(map)